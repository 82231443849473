import { Graph, GraphType } from "../graph/Graph"
import { Interval } from "../navigation/tabs/Tabs"
import classes from "../home/Home.module.css"
import { useEffect, useState } from "react"
import { timestampToDate } from "../helpers/DateHelper"
import { AlarmsList } from "./AlarmsList"
import i18n from "../i18n"

interface IProps {
    startDate: number,
    endDate: number,
    serial: string,
    title: string;
}

export const GlobalDashboardStats = (props: IProps) => {

    const [timer, setTimer] = useState<NodeJS.Timer>()
    const [ , setLastLoadingTimestamp] = useState<number>(Date.now())


    useEffect(() => {
        if (timer) {
            clearInterval(timer)
        }
        setTimer(setInterval(async () => {
            setLastLoadingTimestamp(Date.now())
        }, 15000))
    }, [props.startDate])


    return <>
        <div className={classes.StatsItemContainer}>
            <label>{i18n.t("AlarmFrom")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")} {timestampToDate(props.endDate)} {"-"} {props.title} ({i18n.t("PerDay")})</label>
            <div className={classes.HomeTitleSeparator} />
            <div className={classes.HomeTableContainer} style={{ height: "250px", padding: "20px" }}>
                <Graph
                    startDatePeriodTimestamp={props.startDate}
                    endDatePeriodTimestamp={props.endDate}
                    interval={Interval.DAY}
                    graphType={GraphType.ALARMS_EVOLUTION}
                    preventUpdateAnimations={false}
                    nodePath={""}
                    securispot={props.serial}
                    export={true} />
            </div>
        </div>

        <br />

        <div className={classes.StatsItemContainer}>
            <label>{i18n.t("AlarmFrom")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")} {timestampToDate(props.endDate)} {"-"} {props.title} ({i18n.t("PerHour")}) </label>
            <div className={classes.HomeTitleSeparator} />
            <div className={classes.HomeTableContainer} style={{ height: "250px", padding: "20px" }}>
                <Graph
                    startDatePeriodTimestamp={props.startDate}
                    endDatePeriodTimestamp={props.endDate}
                    interval={Interval.HOUR}
                    graphType={GraphType.ALARMS_EVOLUTION}
                    preventUpdateAnimations={true}
                    nodePath={""}
                    securispot={props.serial}
                    export={true}
                />
            </div>
        </div>

        <br />

        <AlarmsList numberDays={1} heigth={'25vh'} type={undefined} serial={props.serial} title={props.title} />

        <br />


        {/* <div className={classes.StatsItemContainer}>
            <label>Disponibilité du dispositif du {timestampToDate(props.startDate)} au {timestampToDate(props.endDate)} provenant de {props.title} ( par heure )</label>
            <div className={classes.HomeTitleSeparator} />
            <div className={classes.HomeTableContainer} style={{ height: "250px", padding: "20px" }}>
                <Graph
                    startDatePeriodTimestamp={props.startDate}
                    endDatePeriodTimestamp={props.endDate}
                    interval={Interval.HOUR}
                    graphType={GraphType.STATUS_EVOLUTION}
                    preventUpdateAnimations={true}
                    nodePath={""}
                    securispot={props.serial}
                    export={false}
                    updateTrigger={props.updateTrigger}
                />
            </div>
        </div> */}

    </>

}