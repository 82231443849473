import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import i18n from "../../i18n";
import classes from "./Tabs.module.css";
import { getI18n } from "react-i18next";
import { DateRangePicker } from "rsuite";
import { subDays, subHours } from "date-fns";
import { getLastWeekInterval, isToday } from "../../helpers/DateHelper";
import { ValueType } from "rsuite/lib/DateRangePicker";
import { saveRangeDate } from "../../helpers/Storage";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FilterModal } from "./filter/Filter";
const { allowedMaxDays, before, afterToday, combine } = DateRangePicker;

export interface TabItem {
  label: string;
  path: string;
  icon?: IconDefinition;
}

export enum Interval {
  HOUR,
  DAY,
}

export const getIntervalString = (interval: Interval): string => {
  switch (interval) {
    case Interval.HOUR:
      return "hour";
    case Interval.DAY:
      return "day";
  }
};

interface IProps {
  items: TabItem[];
  onSelect: (tabItem: TabItem) => void;
  selectedTab: TabItem | undefined;
  onPeriodChange: (value: any, event: React.SyntheticEvent) => void;
  interval: Interval;
  onIntervalChanged: (interval: Interval) => void;
  startDatePeriodTimestamp: number;
  endDatePeriodTimestamp: number;
  serials: string[];
  selectSerials: (selection: string[]) => void;
  selectedSerials: string[];
  noPadding?: boolean | undefined;
}

export const Tabs = (props: IProps) => {
  let selectedTab: string | undefined = undefined;
  const now = new Date(Date.now());
  const midnightToday = new Date(now.setHours(0, 0, 0, 0));

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    props.selectedSerials
  );

  if (!(props.selectedTab === undefined && props.items.length === 0)) {
    selectedTab =
      props.selectedTab !== undefined
        ? props.selectedTab.path
        : props.items[0].path;
  }

  const tabsItems = props.items.map((it: TabItem) => {
    return (
      <NavLink
        key={it.path}
        to={it.path}
        className={classes.tabItem}
        style={{ textDecoration: "none" }}
        onClick={() => props.onSelect(it)}
      >
        <label
          className={
            it.path === selectedTab ? classes.selectedTab : classes.tab
          }
        >
          {it.icon ? <FontAwesomeIcon icon={it.icon} /> : null} {it.label}
        </label>
        {it.path === selectedTab ? (
          <div className={classes.tabSelectedLine} />
        ) : undefined}
      </NavLink>
    );
  });

  const onPeriodChange = (dates: ValueType, event: React.SyntheticEvent) => {
    if (dates.length >= 2) {
      const startDate = dates[0]!.getTime();
      const endDate = dates[1]!.getTime();
      // console.log("Start date on period change tab", startDate);
      // console.log("End date on period change tab", endDate);
      const now = Date.now();

      // Determine the interval type
      const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
      if (
        endDate + oneDayInMilliseconds - 1 - startDate >
        oneDayInMilliseconds
      ) {
        props.onIntervalChanged(Interval.DAY);
      } else {
        props.onIntervalChanged(Interval.HOUR);
      }

      // Adjust the end date if it's in the future
      let adjustedEndDate = endDate + 1000 * 60 * 60 * 24 - 1; // Add 23h59m59s to the end date
      // console.log("Adjusted end date", adjustedEndDate);
      if (adjustedEndDate > now) {
        adjustedEndDate = now;
      }

      const timestampDates = [startDate, adjustedEndDate];

      props.onPeriodChange(timestampDates, event);
    }
  };

  const openFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const applyFilters = () => {
    console.log("Selected options:", selectedOptions);
    props.selectSerials(selectedOptions);
    closeFilterModal();
  };

  useEffect(() => {
    setSelectedOptions(props.selectedSerials);
  }, [isFilterModalOpen]);

  return (
    <div
      className={[
        classes.tabContainer,
        props.noPadding === true ? classes.tabContainerNoPadding : undefined,
      ].join(" ")}
    >
      <div className={classes.tabContent}>
        {tabsItems}

        <div className={classes.TabsFiltersContainer}>
          {props.selectedTab?.path.endsWith("alarms") ? undefined : (
            <div className={classes.DashboardDateRangeContainer}>
              <DateRangePicker
                disabledDate={combine(
                  combine(allowedMaxDays(31), afterToday()),
                  before("2024-01-01")
                )}
                placement={"bottomEnd"}
                cleanable={false}
                value={
                  props.startDatePeriodTimestamp !== undefined &&
                  props.endDatePeriodTimestamp !== undefined
                    ? [
                        new Date(props.startDatePeriodTimestamp),
                        new Date(props.endDatePeriodTimestamp),
                      ]
                    : undefined
                }
                placeholder={getI18n().t("SelectPeriod")}
                onChange={onPeriodChange}
                ranges={[
                  {
                    label: i18n.t("Today"),
                    value: [midnightToday, now],
                  },
                  {
                    label: i18n.t("Last24Hours"),
                    value: [subHours(new Date().setHours(0,0,0), 24), subDays(new Date().setHours(23,59,59,999), 1)],
                  },
                  {
                    label: i18n.t("Last7Days"),
                    value: [subDays(new Date().setHours(0,0,0), 6), subDays(new Date().setHours(23,59,59,999), 1)],
                  },
                  // {
                  //   label: i18n.t("LastMonth"),
                  //   value: [subDays(new Date(), 30), subDays(new Date(), 1)],
                  // },
                  {
                    label: i18n.t("LastWeek"),
                    value: getLastWeekInterval(),
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.FilterIcon}>
        <FontAwesomeIcon icon={faFilter} onClick={openFilterModal} />
      </div>
      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={closeFilterModal}
        options={props.serials}
        selectedOptions={selectedOptions}
        onChange={setSelectedOptions}
        applyChange={applyFilters}
        selectedSerials={props.selectedSerials}
      />
    </div>
  );
};
