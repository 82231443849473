import React, {Component} from "react"
import classes from "./Card.module.css";

import PropTypes from "prop-types"
import Loader from "react-loader-spinner";
import {getI18n, Trans} from "react-i18next"
import {Link} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearchPlus} from "@fortawesome/free-solid-svg-icons";

export const CardProps = {
    
}

class Card extends Component {


    state = {
        value: undefined,
        isLoading: true,
        error: undefined
    }



    async componentDidMount() {
        if(this.props.infoType !== undefined){
            await this.getValue()
        }


    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.infoType !== undefined && (this.props.startDatePeriodTimestamp !== prevProps.startDatePeriodTimestamp || this.props.nodePath !== prevProps.nodePath || this.props.deviceSerial !== prevProps.deviceSerial || this.props.modacMacAddr !== prevProps.modacMacAddr)){
            await this.getValue()
        }
    }

    getValue = async() => {
        if(!this.state.isLoading){
            await this.setState({isLoading: true})
        }
        let response = undefined
        switch (this.props.infoType) {
            
            default: {
                response = undefined;
            }
        }
        if(response === undefined || response.data === undefined){
            this.setState({
                isLoading: false,
                error: response !== undefined ? response.error : new Error("Unknown error")
            })
            return
        }

        this.setState({
            error: undefined,
            isLoading: false,
            value: response.data
        })
    }


    render() {
        console.log("render card")
        return (
            <div className={classes.Card} style={{backgroundColor: this.props.backgroundColor === undefined ? this.props.infoType.color : this.props.backgroundColor, ...this.props.style}} >
                {
                    <>
                        <label>{this.props.title === undefined ? this.props.infoType.title : this.props.title}</label>

                        {this.state.error !== undefined ? <label>-</label> :

                        this.state.isLoading && this.props.infoType !== undefined ? <Loader type="Oval" color="#FFFFFF" height={30} width={30}/>  :
                            <>
                                <label>
                                    {this.props.value === undefined ?
                                        (this.state.value) >= 0 ?`${this.state.value.toFixed(this.props.infoType.decimalsCount)} ${this.props.infoType.unit}` : "-" :
                                        this.props.value
                                    }
                                </label>
                                {this.props.detailsLink !== undefined  ?
                                    <label>
                                        <Link to={this.props.detailsLink} className={classes.CardDetailsButton}><FontAwesomeIcon icon={faSearchPlus}/> <Trans>Details</Trans></Link>
                                    </label>
                                    : undefined
                                }
                            </>
                        }
                    </>
                }

            </div>
        )
    }
}

Card.propTypes = {
    infoType: PropTypes.oneOf([CardProps.CONTROLS_COUNT, CardProps.AVERAGE_CONTROLS_DURATION, CardProps.CONTROLS_WITH_ALARM_COUNT, CardProps.ALARM_PERCENT, CardProps.CLIENTS_ERROR_PERCENT, CardProps.SYNC_COUNT, CardProps.AVERAGE_MODAC_AVAILABILITY, CardProps.ACTIVE_MODACS_COUNT]).isRequired,
    selectedClientKey: PropTypes.string,
    selectedMediaspotName: PropTypes.string,
    startDatePeriodTimestamp: PropTypes.number,
    endDatePeriodTimestamp: PropTypes.number,
    modacMacAddr: PropTypes.string,
    deviceSerial: PropTypes.string,

    title: PropTypes.string,
    value: PropTypes.string,
    backgroundColor: PropTypes.string,

    detailsLink: PropTypes.string,

    nodePath: PropTypes.string,

    forceLoadingDisplay: PropTypes.bool
}

export default Card
