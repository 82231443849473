/*
 * Created Date: 2024-03-Sa
 * Author: Lucas Barrez
 * -----
 * Last Modified: Wednesday, 3rd April 2024 12:20:36 am
 * Modified By: Lucas Barrez
 * -----
 * HISTORY:
 */

import React, { useEffect, useRef, useState } from "react";
import classes from "../Tabs.module.css";
import { findHost } from "../../../helpers/MappingSecurispot";
import i18n from "../../../i18n";

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  options: string[];
  selectedOptions: string[];
  onChange: (selectedOptions: string[]) => void;
  applyChange: () => void;
  selectedSerials: string[];
}

export const FilterModal: React.FC<FilterModalProps> = ({
  isOpen,
  onClose,
  options,
  selectedOptions,
  onChange,
  applyChange,
  selectedSerials,
}) => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [sortedOptions, setSortedOptions] = useState<
    { option: string; name: string }[]
  >([]);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const handleCheckboxChange = (option: string) => {
    let updatedSelection: string[] = [];

    if (selectedOptions.includes(option)) {
      updatedSelection = selectedOptions.filter((item) => item !== option);
    } else {
      updatedSelection = [option];
    }

    onChange(updatedSelection);

    if (updatedSelection.length === 1) {
      setSelectAllChecked(false);
    }
  };

  const handleSelectAll = () => {
    if (!selectAllChecked) {
      onChange(options.filter((option) => !option.includes("###SERIAL###")));
    } else {
      onChange([]);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  useEffect(() => {
    if (selectedSerials.length !== options.length) {
      setSelectAllChecked(false);
    } else {
      setSelectAllChecked(true);
    }

    // Filtrer les options pour exclure celles contenant "###SERIAL###"
    const filteredOptions = options.filter(
      (option) => !option.includes("###SERIAL###")
    );

    // Création d'un tableau de paires { option, name }
    const optionsWithNames = filteredOptions.map((option) => ({
      option,
      name: findHost(option),
    }));

    // Séparer les options en deux groupes
    const differentNameOptions = optionsWithNames.filter(
      ({ option, name }) => option !== name
    );
    const sameNameOptions = optionsWithNames.filter(
      ({ option, name }) => option === name
    );

    // Trier chaque groupe par ordre alphabétique
    const sortedDifferentNameOptions = differentNameOptions.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const sortedSameNameOptions = sameNameOptions.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    // Concaténer les deux groupes
    const finalSortedOptions = [
      ...sortedDifferentNameOptions,
      ...sortedSameNameOptions,
    ];

    setSortedOptions(finalSortedOptions);
  }, [isOpen, options, selectedSerials]);

  return (
    <>
      {isOpen && (
        <div className={classes.FilterWindow} ref={modalRef}>
          <div className={classes.ModalContent}>
            <span className={classes.ModalClose} onClick={onClose}>
              &times;
            </span>
            <h2 className={classes.ModalTitle}>{i18n.t("SelectDevice")} :</h2>
            <div className={classes.ModalOption}>
              <input
                type="checkbox"
                id="selectAll"
                checked={selectAllChecked}
                onChange={handleSelectAll}
              />
              <label className={classes.ModalTitleOption} htmlFor="selectAll">
              {i18n.t("SelectAll")}
              </label>
            </div>
            {sortedOptions.map(({ option, name }) => (
              <div key={option} className={classes.ModalOption}>
                <input
                  type="checkbox"
                  id={option}
                  value={option}
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                  disabled={selectAllChecked}
                />
                <label className={classes.ModalTitleOption} htmlFor={option}>
                  {name}
                </label>
              </div>
            ))}
          </div>
          <button className={classes.ModalApply} onClick={applyChange}>
          {i18n.t("ApplyFilters")}
          </button>
        </div>
      )}
    </>
  );
};
