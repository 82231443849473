import React, { useEffect, useRef, useState } from "react";
import { Chart, ChartDataSets } from "chart.js";
import "chartjs-plugin-zoom";
import { Trans } from "react-i18next";

import classes from "./Graph.module.css";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faRedo } from "@fortawesome/free-solid-svg-icons";
import { Interval } from "../navigation/tabs/Tabs";
import { saveAs } from "file-saver";
import { graphProps as incidentsEvolutionProps } from "./graphprops/IncidentsEvolutionProps";
import { graphProps as alertsEvolutionProps } from "./graphprops/AlertsEvolutionProps";
import { graphProps as alertsEvolutionWithLegendProps } from "./graphprops/AlertsEvolutionWithLegendProps";
import { graphProps as statusEvolutionProps } from "./graphprops/StatusEvolutionProps";

import { getIncidentsEvolutionDataset } from "./datasets/IncidentsEvolutionDataset";
import { getStatusEvolutionDataset } from "./datasets/StatusEvolutionDataset";
import { getAlarmsEvolutionDataset } from "./datasets/AlarmsEvolutionDataset";
import { getAlarmsEvolutionPerTypesDataset } from "./datasets/AlarmsEvolutionPerTypeDataset";

import {
  getAlerts,
  getAlertsEvolution,
  getAlertsPerType,
  getAvailabilityEvolution,
  getDetections,
} from "../api/DetectionsRequests";

import { ApiDataResponse } from "../api/Request";
import { findHost } from "../helpers/MappingSecurispot";
import { DateTime } from "luxon";
import i18n from "../i18n";
import {
  convertUTCToLocal,
  getDaysBetweenTimestamps,
} from "../helpers/DateHelper";
import { forEachChild } from "typescript";
import {
  DataAlarmEvolutionType,
  DataDefaultItem,
  processData,
} from "../helpers/AlarmProcessing";

export enum GraphType {
  INCIDENTS_COUNT,
  ALARMS_COUNT,
  AREA_IN_ALARMS_EVOLUTION,
  HAT_ALARMS_EVOLUTION,
  LYING_PERSON_ALARMS_EVOLUTION,
  ALARMS_EVOLUTION,
  STATUS_EVOLUTION,
  OVERLOADED_AREA_ALARM,
}

interface ChartElements {
  _index: number;
  _xScale: {
    _ticks: {
      value: number;
    }[];
  };
}

interface IProps {
  graphType: GraphType;

  interval: Interval;
  startDatePeriodTimestamp: number;
  endDatePeriodTimestamp: number;

  nodePath: string;
  mediaspotSerial?: string;
  mediaspotSerials?: string[];

  preventUpdateAnimations: boolean;
  securispot: string;
  export: boolean;
}

export const Graph = (props: IProps) => {
  const [isContentLoaded, setContentLoaded] = useState<boolean>(false); // Specify if content is loading
  const [error, setError] = useState<string | undefined>(undefined); // Specify if request failed
  const [noData, setNoData] = useState<boolean>(false); // Specify if response is OK but with no data
  const [apiResponseData, setApiResponseData] = useState<
    undefined | ApiDataResponse<any>
  >(undefined);
  //TODO : add data type
  const [chartData, setChartData] = useState<undefined | any>(undefined);

  const [dashboardKey, setDashboardKey] = useState<number>(0);
  const [chartRef, _] = useState<React.RefObject<HTMLCanvasElement>>(
    React.createRef()
  );

  const [chartCtx, setChartCtx] = useState<Chart | undefined>(undefined);

  const refreshDashboard = () => {
    setDashboardKey((prevKey) => prevKey + 1);
  };

  let chart: Chart | undefined = undefined;

  useEffect(() => {
    if (apiResponseData) {
      setGraphData();
    }
  }, [apiResponseData]);

  // Reload each time period/nodepath/interval are updated
  useEffect(() => {
    refreshDashboard();
    getData();
  }, [
    props.interval,
    props.startDatePeriodTimestamp,
    props.endDatePeriodTimestamp,
    props.nodePath,
    props.securispot,
  ]);

  const setGraphData = () => {
    let resp = undefined;
    switch (props.graphType) {
      case GraphType.INCIDENTS_COUNT: {
        resp = getIncidentsEvolutionDataset();
        break;
      }
      // case GraphType.ALARMS_COUNT:{ resp = getAlarmsEvolutionDataset(apiResponseData?.data); break;}
      case GraphType.AREA_IN_ALARMS_EVOLUTION: {
        resp = getAlarmsEvolutionDataset(apiResponseData?.data);
        break;
      }
      case GraphType.HAT_ALARMS_EVOLUTION: {
        resp = getAlarmsEvolutionDataset(apiResponseData?.data);
        break;
      }
      case GraphType.LYING_PERSON_ALARMS_EVOLUTION: {
        resp = getAlarmsEvolutionDataset(apiResponseData?.data);
        break;
      }
      case GraphType.ALARMS_EVOLUTION: {
        resp = getAlarmsEvolutionPerTypesDataset(apiResponseData?.data);
        break;
      }
      case GraphType.OVERLOADED_AREA_ALARM: {
        resp = getAlarmsEvolutionDataset(apiResponseData?.data);
        break;
      }
      case GraphType.STATUS_EVOLUTION: {
        resp = getStatusEvolutionDataset(apiResponseData?.data);
        break;
      }
      default:
        resp = undefined;
    }
    //TODO: set error if resp is undefined
    if (resp !== undefined && resp.error !== undefined) {
      setError("invalid dataset");
      return;
    }

    if (resp !== undefined) {
      setChart(chartRef, resp.datasets, resp.dates, resp.labels, resp.maxValue);
    }
  };

  const getData = async () => {
    setContentLoaded(false);
    setError(undefined);
    setNoData(false);

    //TODO : add serial to request
    let data:
      | ApiDataResponse<any>
      | DataAlarmEvolutionType
      | DataDefaultItem
      | undefined = undefined;
    switch (props.graphType) {
      // case GraphType.INCIDENTS_COUNT:{ data = await getDetections(props.nodePath); break; }
      // case GraphType.ALARMS_COUNT: { data = await getAlerts(props.startDatePeriodTimestamp, props.endDatePeriodTimestamp, props.interval, props.securispot); break; }
      case GraphType.OVERLOADED_AREA_ALARM: {
        data = await getAlertsPerType(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          4,
          props.securispot
        );
        break;
      }
      case GraphType.AREA_IN_ALARMS_EVOLUTION: {
        data = await getAlertsPerType(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          1,
          props.securispot
        );
        break;
      }
      case GraphType.HAT_ALARMS_EVOLUTION: {
        data = await getAlertsPerType(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          2,
          props.securispot
        );
        break;
      }
      case GraphType.LYING_PERSON_ALARMS_EVOLUTION: {
        data = await getAlertsPerType(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          3,
          props.securispot
        );
        break;
      }
      case GraphType.ALARMS_EVOLUTION: {
        data = await getAlertsEvolution(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          props.securispot
        );
        break;
      }
      case GraphType.STATUS_EVOLUTION: {
        data = await getAvailabilityEvolution(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          props.securispot
        );
        break;
      }
      default:
        data = undefined;
    }

    if (data === undefined || data.error !== undefined) {
      setError(data === undefined ? "No data response" : data.error);
      return;
    }
    console.log("LES DATA RECUES : ", data);

    const processedData = processData(
      props.graphType,
      data as DataDefaultItem & DataAlarmEvolutionType,
      props.interval,
      props.startDatePeriodTimestamp,
      props.endDatePeriodTimestamp
    );

    console.log("LES DATA QUI VONT DANS LE GRAPH : ", processedData);

    await setApiResponseData(processedData);
    await setError(undefined);
  };

  const setChart = (
    ref: React.RefObject<HTMLCanvasElement>,
    datasets: ChartDataSets[],
    dates: Date[],
    labels?: string[] | Array<string[]>,
    maxValue?: number
  ) => {
    const myChartRef = ref.current?.getContext("2d");
    if (chart) {
      chart.destroy();
    }

    if (
      datasets.find((it) => it.data !== undefined && it.data.length !== 0) ===
      undefined
    ) {
      setNoData(true);
      setContentLoaded(true);
      return;
    }

    if (datasets)
      switch (props.graphType) {
        case GraphType.INCIDENTS_COUNT: {
          chart = new Chart(
            myChartRef!,
            incidentsEvolutionProps(
              dates,
              props.interval,
              props.startDatePeriodTimestamp,
              props.endDatePeriodTimestamp
            )
          );
          break;
        }
        case GraphType.OVERLOADED_AREA_ALARM: {
          chart = new Chart(
            myChartRef!,
            alertsEvolutionProps(
              dates,
              props.interval,
              props.startDatePeriodTimestamp,
              props.endDatePeriodTimestamp
            )
          );
          break;
        }
        case GraphType.AREA_IN_ALARMS_EVOLUTION: {
          chart = new Chart(
            myChartRef!,
            alertsEvolutionProps(
              dates,
              props.interval,
              props.startDatePeriodTimestamp,
              props.endDatePeriodTimestamp
            )
          );
          break;
        }
        case GraphType.HAT_ALARMS_EVOLUTION: {
          chart = new Chart(
            myChartRef!,
            alertsEvolutionProps(
              dates,
              props.interval,
              props.startDatePeriodTimestamp,
              props.endDatePeriodTimestamp
            )
          );
          break;
        }
        case GraphType.LYING_PERSON_ALARMS_EVOLUTION: {
          chart = new Chart(
            myChartRef!,
            alertsEvolutionProps(
              dates,
              props.interval,
              props.startDatePeriodTimestamp,
              props.endDatePeriodTimestamp
            )
          );
          break;
        }
        case GraphType.ALARMS_EVOLUTION: {
          chart = new Chart(
            myChartRef!,
            alertsEvolutionWithLegendProps(
              dates,
              props.interval,
              props.startDatePeriodTimestamp,
              props.endDatePeriodTimestamp
            )
          );
          break;
        }
        case GraphType.STATUS_EVOLUTION: {
          chart = new Chart(
            myChartRef!,
            statusEvolutionProps(
              dates,
              props.interval,
              props.startDatePeriodTimestamp,
              props.endDatePeriodTimestamp
            )
          );
          break;
        }
        default:
          chart = undefined;
      }

    if (chart !== undefined) {
      chart.data.datasets = datasets;
      setChartData(datasets);
      chart.data.labels = labels;

      if (
        props.preventUpdateAnimations === true &&
        chart.options.animation?.duration
      ) {
        chart.options.animation.duration = 0;
      }
      chart.update();
      setChartCtx(chart);
    }
    setContentLoaded(true);
  };

  const handlePress = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const downloadData = () => {
    if (chartData !== undefined) {
      if (chartData.length > 0) {
        let csvContent =
          ["Type d'alarme", "Date", "Nombre d'alertes"].join(",") + "\n";

        chartData.forEach((dataset: { data: any[]; label: any }) => {
          if (dataset.data) {
            dataset.data.forEach((line) => {
              if (line) {
                if (line.y > 0) {
                  csvContent += `${dataset.label},${
                    props.interval === 1
                      ? DateTime.fromJSDate(line.x).toFormat("yyyy-MM-dd")
                      : DateTime.fromJSDate(line.x).toFormat(
                          "yyyy-MM-dd HH:mm:ss"
                        )
                  },${line.y}\n`;
                }
              }
            });
          }
        });
        const inter = props.interval === 1 ? "par-jour" : "par-heure";
        const securi = props.securispot.length
          ? findHost(props.securispot)
          : "tous-les-SecuriSpots";
        const startDateString = DateTime.fromISO(
          new Date(props.startDatePeriodTimestamp).toISOString()
        ).toFormat("yyyy-MM-dd");
        const endDateString = DateTime.fromISO(
          new Date(props.endDatePeriodTimestamp).toISOString()
        ).toFormat("yyyy-MM-dd");
        const fileName = `${securi}_${inter}_${startDateString}_${endDateString}.csv`;

        // https://stackoverflow.com/questions/19492846/javascript-to-csv-export-encoding-issue
        const csvBlob = new Blob(["\ufeff" + csvContent], {
          type: "text/csv;charset=utf-8",
        });

        saveAs(csvBlob, fileName);
      }
    }
  };

  return (
    <div className={classes.GraphContainer} key={dashboardKey}>
      {isContentLoaded || error ? undefined : (
        <div className={classes.GraphSpinnerContainer}>
          <Loader
            type="Oval"
            color="#004aac"
            height={50}
            width={50}
          />
        </div>
      )}

      {noData ? (
        <div className={classes.GraphSpinnerContainer}>
          <label>
            <Trans>NoDataOnThisPeriod</Trans>
          </label>
        </div>
      ) : undefined}

      {error === undefined ? undefined : (
        <div className={classes.GraphSpinnerContainer}>
          <label>
            <Trans>AnErrorOccurredDuringLoading</Trans>...
          </label>
          <br />
          <button className={classes.RetryButton} onClick={getData}>
            <FontAwesomeIcon icon={faRedo} size={"sm"} color={"#404040"} />
            &nbsp;&nbsp;<Trans>Reload</Trans>
          </button>
        </div>
      )}
      <canvas onMouseDown={(e) => handlePress(e)} ref={chartRef} />
      {props.export ? (
        <button className={classes.DownloadButton} onClick={downloadData}>
          <FontAwesomeIcon icon={faDownload} />
          <span>{i18n.t("Export")}</span>
        </button>
      ) : undefined}
    </div>
  );
};
