import classes from './App.module.css';
import Header from './navigation/header/Header';
import Menu from './navigation/menu/Menu';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Mediaspots from './admin/mediaspots/Mediaspots';
import { withAuth } from './hoc/withAuth';
import { ToastProvider } from 'react-toast-notifications';

import DevicesMapping from './devicesmapping/DevicesMapping';
import RoutersProvisionning from './superadmin/provisionning/RoutersProvisionning';
import MediaspotsProvisionning from './superadmin/provisionning/MediaspotsProvisionning';
import SimProvisionning from './superadmin/provisionning/SimProvisionning';
import Home from './home/Home';



const App = () => {
    return (
        <div className={classes.App}>
            <BrowserRouter>
                <Header/>
                <Menu/>
                <div className={classes.AppContent}>
                    <ToastProvider>
                        <Switch>
                            <Redirect exact from={"/supervision"} to={"/supervision/global"} />
                            <Redirect exact from={"/admin"} to={"/admin/devices"} />
                            <Redirect exact from={"/provisionning"} to={"/provisinning/mediaspots"} />
                            <Redirect exact from={"/"} to={"/supervision"} />

                            <Route path="/supervision" render={() => <Home/>} />
                            <Route path="/supervision/global" render={() => <Home/>} />
                            <Route path="/supervision/helmet" render={() => <Home/>} />
                            <Route path="/supervision/area" render={() => <Home/>} />
                            <Route path="/supervision/proximity" render={() => <Home/>} />

                            
                            <Route path="/admin/mediaspots" render={() => <Mediaspots />} />
                            <Route path="/admin/devices" render={() => <DevicesMapping/>} />
                            
                            <Route path="/provisionning/routers" render={() => <RoutersProvisionning />} />
                            <Route path="/provisionning/mediaspots" render={() => <MediaspotsProvisionning />} />
                            <Route path="/provisionning/sim" render={() => <SimProvisionning />} />
                            

                            
                        </Switch>
                    </ToastProvider>
                </div>
        </BrowserRouter>
        </div>
    )
  }
  
  export default withAuth(App)
  