import config from "../config";
import { getIntervalString, Interval } from "../navigation/tabs/Tabs";
import { ApiDataResponse, post } from "./Request";

export const getDetections = async (
  path: string
): Promise<ApiDataResponse<GetDetectionsApiResponse>> => {
  const body = JSON.stringify({
    nodePath: path,
  });
  return await post<GetDetectionsApiResponse>(
    config.API_URL + "/getdetections",
    body
  );
};

export const getAlerts = async (
  startDateTimestamp: number,
  endDateTimestamp: number,
  interval: Interval,
  serial?: string,
  type? : number,
): Promise<ApiDataResponse<GetAlarmsApiResponse>> => {
  const body = JSON.stringify({
    startDateTimestamp: startDateTimestamp,
    endDateTimestamp: endDateTimestamp,
    interval: getIntervalString(interval),
    serial: serial,
    type: type,
  });
  return await post<GetAlarmsApiResponse>(config.API_URL + "/getalarms", body);
};

export const getAlertsPerType = async (
  startDateTimestamp: number,
  endDateTimestamp: number,
  interval: Interval,
  type: number,
  serial?: string | null
): Promise<ApiDataResponse<GetAlarmsPerTypeApiResponse>> => {
  const body = JSON.stringify({
    startDateTimestamp: startDateTimestamp,
    endDateTimestamp: endDateTimestamp,
    interval: getIntervalString(interval),
    type: type,
    serial: serial,
  });

  return await post<GetAlarmsPerTypeApiResponse>(
    config.API_URL + "/getalarmspertype",
    body
  );
};

export const getAlertsEvolution = async (
  startDateTimestamp: number,
  endDateTimestamp: number,
  interval: Interval,
  serial?: string | null
): Promise<ApiDataResponse<GetAlarmsEvolutionApiResponse>> => {
  const body = JSON.stringify({
    startDateTimestamp: startDateTimestamp,
    endDateTimestamp: endDateTimestamp,
    interval: getIntervalString(interval),
    serial: serial,
  });

  return await post<GetAlarmsEvolutionApiResponse>(
    config.API_URL + "/getalarmsevolution",
    body
  );
};

export const getLastEventDate = async (): Promise<
  ApiDataResponse<GetLastEventDateApiResponse>
> => {
  return await post<GetLastEventDateApiResponse>(
    config.API_URL + "/getlasteventdate"
  );
};

export const getAvailabilityEvolution = async (
  startDateTimestamp: number,
  endDateTimestamp: number,
  interval: Interval,
  serial?: string | null
): Promise<ApiDataResponse<GetAvailabilityEvolutionApiResponse>> => {
  const body = JSON.stringify({
    startDateTimestamp: startDateTimestamp,
    endDateTimestamp: endDateTimestamp,
    interval: getIntervalString(interval),
    serial: serial,
  });

  return await post<GetAvailabilityEvolutionApiResponse>(
    config.API_URL + "/getavailabilityevolution",
    body
  );
};

export type GetDetectionsApiResponse = Array<{
  gsi1sk: string;
  pk: string;
  sk: string;
  date: string;
  nb_frames: number;
  fps: number;
}>;

export type GetAlarmsApiResponse = Array<{
  date: string;
  id: number;
  duration: number;
  description: string;
  securispot: string;
}>;

export type GetAlarmsPerTypeApiResponse = Array<{
  hostname: string;
  data: Array<{
    date: string;
    count: number;
  }>;
}>;

// type DeviceAlarmData = {
//   rule1: number;
//   rule2: number;
//   rule3: number;
// };

// type DeviceData = {
//   [deviceName: string]: DeviceAlarmData;
// };

// export type GetAlarmsEvolutionApiResponse = Array<{
//   date: string;
//   devices: DeviceData;
// }>;

export type GetAlarmsEvolutionApiResponse = Array<{
  date: string;
  rule1: number;
  rule2: number;
  rule3: number;
  rule4: number;
}>;

export type GetAvailabilityEvolutionApiResponse = Array<{
  hostname: string;
  data: Array<{
    date: string;
    online: boolean;
  }>;
}>;

export type GetLastEventDateApiResponse = {
  date: string;
};
