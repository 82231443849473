import React, {Component} from 'react';
import classes from "./Tree.module.css"
import {
    faChevronDown,
    faChevronRight, faLock,
    faPen,
    faPlug,
    faPlus,
    faSlidersH, faTrash,
    faUnlink
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import PropTypes from 'prop-types';

import Tree from "./Tree";
import ReactTooltip from 'react-tooltip';
import NewArea from  "./newarea/NewArea"
import {getNodeTypeFromString, NodeType} from "./NodeType"

import DroppableArea from "./droppablearea/DroppableArea";
import {getChildKeys} from "../../helpers/factories/FactoriesHelper";
import {deleteDevice} from "../../api/DevicesRequests"
import {deleteNode} from "../../api/OrganizationRequests"
import {getI18n} from "react-i18next";
import {getClientUUID} from "../../api/Auth"
import {timeAgoInSeconds} from "../../helpers/DateHelper";

const getPaddingLeft = (level) => {
    return level * 15;
}

const RadiameterState = {
    COMPLIANT: "COMPLIANT",
    UNAVAILABLE_AVIOR: "UNAVAILABLE_AVIOR",
    AVAILABLE_AVIOR_AND_SHOULDNT_BE: "AVAILABLE_AVIOR_AND_SHOULDNT_BE",
    PROBE_BAD_CONNECTION: "PROBE_BAD_CONNECTION",
    INACTIVE: "INACTIVE"
}


class TreeNode extends Component {

    state = {
        isHover: false,
        deletionInProgress: false,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        ReactTooltip.rebuild()
    }

    handleMouseHover = (isHover) => {
        this.setState({isHover: isHover})
    }

    onRowClick = (event, node, shouldForceToggle, preventSelection) => {
        if(node.type === "modac" && this.props.isAreaManagement === false){
            this.props.onSelectModac(node, this.props.nodeId, this.props.path)

        }else{
            if((event.target.attributes.id === undefined || event.target.attributes.id.value !== "AddItemButton")){
                if(node.isOpen === true){ // if node is already opened
                    if(shouldForceToggle === true){
                        this.props.onToggle(node)
                    }
                }else { // if node is not opened yet, all treenode is clickable to open it
                    this.props.onToggle(node)
                }
            }

            // Do not select if node is selected and click is on node
            if(preventSelection === undefined || preventSelection === false){
                //alert(preventSelection)
                this.onNodeSelect(node)
            }else{
                this.forceUpdate()
            }
        }
    }


    onNodeSelect = (node) => {
        this.props.onNodeSelect(node, this.props.nodeId, this.props.path)
        this.forceUpdate()
    }


    isOnline = (logtype, lastReceivedEventDateString) => {
        const lastReceivedEventTimestamp = Date.parse(lastReceivedEventDateString)
        const now = Date.now()
        const diff = now-lastReceivedEventTimestamp
        if(logtype === "REALTIME"){
            return diff < (1000 * 60 * 5) // < 5 min
        }else {
            return diff < (1000 * 60 * 60 * 24) // < 24h
        }
    }

    getMediaspotPictoColor = (node) => {
        const lastReceivedEvent = node.router[0].last_received_event
        const lastContactReceivedDate = new Date(lastReceivedEvent)
        if(timeAgoInSeconds(lastContactReceivedDate.getTime() / 1000) < 60 * 60 * 24){
            return "green"
        }else if(timeAgoInSeconds(lastContactReceivedDate.getTime() / 1000) < 60 * 60 * 24 * 30){
            return "orange"
        }else {
            return "red"
        }
    }



    getRadiameterState = (node) => {

        // Check if one of probe is not in the expected state
        if(node.configSet.mustAviorBeOnline !== node.configDetected.isAviorOnline){
            return node.configSet.mustAviorBeOnline === true ? RadiameterState.UNAVAILABLE_AVIOR : RadiameterState.AVAILABLE_AVIOR_AND_SHOULDNT_BE
        }
        // Check if all probe are in expected state and at least one probe is available
        if(node.configDetected.isAviorOnline === false){
            return RadiameterState.INACTIVE
        }

        if(node.configSet.p1.mustBeAvailable === node.configDetected.p1.isAvailable && node.configSet.p2.mustBeAvailable === node.configDetected.p2.isAvailable){
            return RadiameterState.COMPLIANT
        }else {
            return RadiameterState.PROBE_BAD_CONNECTION
        }
    }


    getRadiameterStateIconColor = (node) => {
        const radiameterState = this.getRadiameterState(node)
        switch (radiameterState) {
            case RadiameterState.COMPLIANT:
                return "rgba(63,173,23,0.75)"
            case RadiameterState.INACTIVE:
                return "rgba(87,87,87,1.00)"
            default:
                return "rgba(173,44,25,0.75)"
        }
    }


    getConfigPictoColor = (node) => {
        // If any detected config is incorrect, return error color
        if(node.configSet.p1.ch1.expectedRadiationType !== node.configDetected.p1.ch1.detectedRadiationType ||
            node.configSet.p1.ch2.expectedRadiationType !== node.configDetected.p1.ch2.detectedRadiationType ||
            node.configSet.p2.ch1.expectedRadiationType !== node.configDetected.p2.ch1.detectedRadiationType ||
            node.configSet.p2.ch2.expectedRadiationType !== node.configDetected.p2.ch2.detectedRadiationType){
            return "rgba(173,44,25,0.75)"
        }

        // If config is correct and at least one probe is available, return correct color
        if(node.configDetected.p1.isAvailable === true || node.configDetected.p2.isAvailable === true){
            return "rgba(63,173,23,0.75)"
        }

        // If config is correct and no probe connected neutral color
        return "rgba(87,87,87,1.00)"
    }

    onAddClick = (event, node) => {
        event.stopPropagation()

        if(node.isOpen !== true){
            this.onRowClick(event, node, true, true)
        }

        this.props.onAddClick(this.props.path)
    }

    onEditClick = (event) => {
        event.stopPropagation()
        this.setState({ isHover: false }, () => {
            this.props.onEditClick(this.props.nodeId)
        })
    }

    onDeleteNodeClick = async(event) => {
        event.stopPropagation()
        this.setState({deletionInProgress: true}, async() => {

            if(window.confirm(getI18n().t("AreYouSureYouWantToRemoveThisArea"))){
                const response = await deleteNode(this.props.path, this.props.orgTypeUUID)
                this.setState({deletionInProgress: false})
                if(response.error !== undefined){
                    alert("An error occurred during area deletion")
                    return
                }
                this.props.onNodeDeleted(this.props.nodeId, this.props.path)
            }else {
                this.setState({deletionInProgress: false})
            }

        })
    }

    onDeleteDeviceClick = async(event) => {
        event.stopPropagation()
        console.log(this.props.node.nodePath)
        console.log(this.props.node.id)

        this.setState({deletionInProgress: true}, async() => {

            if(window.confirm(getI18n().t("AreYouSureYouWantToRemoveThisDevice"))){
                const response = await deleteDevice(this.props.node.nodePath, this.props.node.id, getClientUUID())
                this.setState({deletionInProgress: false})
                if(response.error !== undefined){
                    alert("An error occurred during device deletion")
                    return
                }
                this.props.onNodeDeleted(this.props.nodeId, this.props.path)
            }else {
                this.setState({deletionInProgress: false})
            }

        })
    }

    onDissociateClick = (event) => {
        event.stopPropagation()
        console.log(this.props)
        this.props.onDissociateDevice(this.props.node, this.props.path)
    }

    onItemDroppedForReplacement = (draggedItemId, dropItemId) => {
        alert(`Replace ${draggedItemId} with ${dropItemId}`)
    }

    onItemDropped = (draggedItemType, fullObject) => {
        const sourceNodePath = draggedItemType === NodeType.MEDIASPOT ? this.props.path : this.props.node.nodePath
        switch (draggedItemType){
            case NodeType.MEDIASPOT: this.props.onMediaspotDropped(this.props.path, fullObject, sourceNodePath); break;
            case NodeType.SENSOR: this.props.onSensorDropped(this.props.path, fullObject, sourceNodePath); break;
            default: return;
        }
    }

    onNodeIconClick = (e) => {
        e.stopPropagation()
        this.onRowClick(e, this.props.node, true, true)
    }

    onDeleteNode = () => {

    }

    getTooltipFromNode = (node) => {
        let tooltip = undefined;
        if(node.hasMediaspot && node.mediaspot !== undefined){
            tooltip = "";
            tooltip +=  `Device serial : ${node.mediaspot.serial}`

            if(node.mediaspot.lastWSContact){
                tooltip += `<br />`
                tooltip += "Last contact : " + node.mediaspot.lastWSContact
            }
        }
        if(node.type === "modac"){
            tooltip = "";
            tooltip +=  `Mac address : ${node.macaddr}<br />`
            tooltip += "Last received event : " + node.last_received_event
        }
        return tooltip
    }


    render(){
        const { node, getChildNodes, levelIndex } = this.props;
        const isSelectedDevice = (this.props.alreadySelectedNodeId !== undefined && this.props.alreadySelectedNodeId === this.props.nodeId)

        const nodeType = getNodeTypeFromString(node.type)
        const isModac = nodeType === NodeType.MODAC

        // console.log(node)

        let modacPictoColor = "#575757"
        if(isModac){
            const isOnline = this.isOnline(node.log_type, node.last_received_event)
            if(this.props.isAreaManagement === true){
                modacPictoColor = undefined
            }
            else if(isOnline && node.log_type === "REALTIME" && node.status === "ACTIVE"){
                modacPictoColor = "rgba(63,173,23,0.75)"

                if(node.detectedMediaspotSerial !== undefined && node.parent_key !== undefined && node.parent_key.includes("mediaspot-")){
                    const extractedSerial = node.parent_key.replace("mediaspot-", "").split("|")[0]
                    if(extractedSerial !== node.detectedMediaspotSerial){
                        modacPictoColor = "rgb(255,119,0)"
                    }
                }
            }else if(!isOnline && node.log_type === "REALTIME" && node.status === "ACTIVE"){
                modacPictoColor = "rgba(173,44,25,0.75)"
            }else {
                modacPictoColor = "rgba(87,87,87,1.00)"
            }
        }

        let level = undefined
        if(nodeType === NodeType.AREA) {
            level = Tree.getTypeFromString(this.props.types, this.props.node.type)
        }

        if((this.props.hideArchiveDevices === true && node.isArchiveDevice === true) || (this.props.hideVisibleNodes === true && node.isVisible === false) || (this.props.hideStoreNodes === true && node.isStore === true)){
            return <div/>;
        }

        const childsKeys = getChildKeys(node)
        let lowerChildLevelIndex = undefined;
        const childNodesLevels = getChildKeys(node).map(key => node[key]).map(node => Tree.getTypeFromString(this.props.types, node.type)).filter(it => it !== undefined).map(it => it.level)
        if(childNodesLevels.length > 0){
            lowerChildLevelIndex = Math.min(...childNodesLevels)
        }

        let isGoodConfiguredMediaspot = true
        if(node.detectedMediaspotSerial !== undefined && node.parent_key !== undefined && node.parent_key.includes("mediaspot-")){
            const extractedSerial = node.parent_key.replace("mediaspot-", "").split("|")[0]
            if(extractedSerial !== node.detectedMediaspotSerial){
                isGoodConfiguredMediaspot = false
            }
        }

        return (
            <>
                {this.props.isEditing === true ?  <NewArea label={node.val}
                                                           path={this.props.path}
                                                           type={level}
                                                           types={this.props.types}
                                                           lowerChildLevelIndex={lowerChildLevelIndex}
                                                           parentLevelIndex={this.props.parentLevel+1}
                                                           currentTypeIndex={level !== undefined ? level.level : 0}
                                                           onNodeEdited={(label, type) => this.props.onNodeEdited(this.props.path + "|" + this.props.nodeId, label, type)}
                                                           style={{marginLeft: `${getPaddingLeft(levelIndex)}px`}}
                                                           onNodeEditionCancelled={this.props.onNodeEditionCancelled}
                                                           orgTypeUUID={this.props.orgTypeUUID}/> :

                    <div className={classes.TreeNode} style={{paddingLeft: `${getPaddingLeft(levelIndex)}px`, backgroundColor: (this.props.deviceToBlink !== undefined && this.props.deviceToBlink.pk === node.id) ? "#ffeb85": undefined}}
                         onClick={(event) => this.onRowClick(event, node)}
                         onMouseEnter={() => this.handleMouseHover(true)}
                         onMouseLeave={() => this.handleMouseHover(false)}>


                        <DroppableArea 
                            deviceId={nodeType !== NodeType.AREA ? node.id : undefined}
                            hasMediaspot={node.hasMediaspot} onItemDropped={this.onItemDropped}
                            onItemDroppedForReplacement={this.onItemDroppedForReplacement}
                            dropAllowed={((node.isAllowed === undefined || node.isAllowed === true) && this.props.inheritOfStore === false) && this.props.inheritOfStore !== true}
                            path={this.props.path}
                            nodeType={nodeType}
                            node={node}
                            onClick={undefined}
                            leftPadding={getPaddingLeft(levelIndex)}
                            isArchive={node.isArchiveDevice}
                            reference={node.val}/>

                        <div className={classes.NodeIcon}>
                            { ((childsKeys.length > 0 && nodeType === NodeType.AREA) || (/*(nodeType === NodeType.AREA && this.props.isAreaManagement === false) || */(nodeType !== NodeType.PROBE && this.props.isAreaManagement === true && childsKeys.length > 0))) ?
                                <div onClick={this.onNodeIconClick} id={(node.isOpen) ? "chevron_down" : "chevron_up"}><FontAwesomeIcon fixedWidth={true} icon={node.isOpen ? faChevronDown : faChevronRight}/></div> : <div style={{width: "15px", height:"1px"}}/>}
                        </div>

                        {/* Node prefix picto, mediaspot icon, dynamic icon from types or modac icon */}
                        <div className={classes.NodeIcon} style={{marginRight: "10px"}} data-event={"click"} data-text-color={"#000000"} data-place={"left"} data-class={classes.tooltip} data-border={true} data-border-color={"#c3bdb9"} data-background-color={"white"} data-multiline={true} data-tip={this.getTooltipFromNode(node)}>
                            {node.isStore === true ? <div><i className={`fa fa-warehouse fa-fw`}/></div> :

                                (nodeType === NodeType.AREA && !(node.hasMediaspot && !this.props.isAreaManagement)) && <div><i style={{color: this.state.deletionInProgress === true ? "#bebebe" : undefined}} className={`fa ${level !== undefined ? level.icon : "fa-square"} fa-fw`}/></div>}
                            {nodeType === NodeType.MODAC && <div><i style={{color: modacPictoColor}} className={`fa fa-microchip fa-fw`}/></div>}
                            {((nodeType === NodeType.AREA && this.props.isAreaManagement === false && node.hasMediaspot) || nodeType === NodeType.MEDIASPOT) && <div><i style={{color: this.props.isAreaManagement === true ? undefined : this.getMediaspotPictoColor(node)}} className={`fa fa-wifi fa-fw`}/></div>}
                            {nodeType === NodeType.RADIAMETER && <div><i className={`fa fa-weight fa-fw`}/></div>}
                            {nodeType === NodeType.ROUTER && <div><i className={`fa fa-ethernet fa-fw`}/></div>}
                            {nodeType === NodeType.PROBE && <div><i className={`fa fa-radiation fa-fw`}/></div>}
                            {nodeType === NodeType.SIM && <div><i className={`fa fa-sim-card fa-fw`}/></div>}

                        </div>


                        {/* Node name and state icons (only displayed when node is a modac and any props to prevent state loading */}
                        <label className={classes.TreeNodeLabel} style={
                            isSelectedDevice ?
                            {color: (node.isAllowed === true || this.state.deletionInProgress === true) ? "#2583e6" : (isGoodConfiguredMediaspot === false ? "#e75f02" : "#575757"), fontWeight: "bold", cursor: "pointer"} :
                                { cursor: "pointer", color:isSelectedDevice ? "#2583e6" : (node.status === "INACTIVE" || node.isArchiveDevice === true || node.isAllowed === false || node.isVisible === false || this.state.deletionInProgress === true) ? "#ADADAD" : isGoodConfiguredMediaspot === false ? "#e75f02" : "#575757"}}>
                            {node.val}&nbsp;
                            {nodeType === NodeType.MODAC && (this.props.isAreaManagement !== true) ?
                                <>
                                    <FontAwesomeIcon color={this.getRadiameterStateIconColor(node)} icon={faPlug}/>&nbsp;&nbsp;
                                    <FontAwesomeIcon color={this.getConfigPictoColor(node)} icon={faSlidersH}/>
                                </> :
                                undefined}
                        </label>

                        {/* Add/edit button on areas*/}
                        {
                            (this.state.isHover === true && this.props.enableEdition && nodeType === NodeType.AREA && level !== undefined) ?
                                <>
                                    { (level.child !== undefined && node.isStore !== true) ? <button id={"add_area_button"} title={"add"} onClick={(event) => this.onAddClick(event, node)} className={classes.NodeIconAddButton}><FontAwesomeIcon className={classes.NodeIconEditButtonPicto} size={"sm"} icon={faPlus}/></button> : undefined }
                                    { (level.level >= 0
                                        ? <button onClick={this.onEditClick} className={classes.NodeIconEditButton}><FontAwesomeIcon className={classes.NodeIconEditButtonPicto} size={"sm"} icon={faPen}/></button>
                                        : <button className={classes.NodeIconEditButton}><FontAwesomeIcon className={classes.NodeIconEditButtonPicto} size={"sm"} icon={faLock}/></button>)}
                                </>
                                : undefined
                        }

                        {
                            // Device dissociation button
                            (this.state.isHover === true && this.props.enableEdition && (node.type === NodeType.MEDIASPOT || node.type === NodeType.SENSOR) && this.props.inheritOfStore === false && node.isArchiveDevice !== true) ?
                                <button disabled={this.state.deletionInProgress === true} title={"Dissociate"} onClick={this.onDissociateClick} className={classes.NodeIconDissociateButton}><FontAwesomeIcon className={classes.NodeIconDissociateButtonPicto} size={"sm"} icon={faUnlink}/></button>
                                : undefined
                        }

                        {
                            // Archived device deletion button
                            (this.state.isHover === true && this.props.enableEdition && (node.type === NodeType.MEDIASPOT || node.type === NodeType.SENSOR) && this.props.inheritOfStore === false && node.isArchiveDevice === true && childsKeys.length === 0 ) ?
                                <button disabled={this.state.deletionInProgress === true} title={"Delete"} onClick={this.onDeleteDeviceClick} className={classes.NodeIconDissociateButton}><FontAwesomeIcon className={classes.NodeIconDissociateButtonPicto} size={"sm"} icon={faTrash}/></button>
                                : undefined
                        }

                        {
                            // Area deletion button
                            (this.state.isHover === true && node.isStore !== true && this.props.enableEdition && (nodeType === NodeType.AREA) && childsKeys.length === 0 ) ?
                                <button disabled={this.state.deletionInProgress === true} title={"Delete"} onClick={this.onDeleteNodeClick} className={classes.NodeIconDeleteButton}><FontAwesomeIcon className={classes.NodeIconDeleteButtonPicto} size={"sm"} icon={faTrash}/></button>
                                : undefined
                        }



                    </div>}

                {this.props.newNodeAdding === this.props.path ?  <NewArea
                    onNewNodeCreated={(id, label, type, isStore) => this.props.onNewNodeCreated(id, label, type, this.props.path, isStore)}
                    onNodeAddingCancelled={this.props.onNodeAddingCancelled}
                    currentTypeIndex={level !== undefined ? level.level : 0}
                    parentLevelIndex={level !== undefined ? level.level : 0}
                    types={this.props.types}
                    path={this.props.path}
                    orgTypeUUID={this.props.orgTypeUUID}
                    style={{marginLeft: `${getPaddingLeft(levelIndex + 1, node.type)}px`}} /> : undefined}



                { node.isOpen && getChildNodes(node, nodeType).map(childNodeMap => {
                    return <TreeNode
                        {...this.props}
                        key={childNodeMap.id}
                        node={childNodeMap.node}
                        levelIndex={levelIndex + 1}
                        nodeId={childNodeMap.id}
                        //TODO: Check router type here 
                        path={(childNodeMap.node.type === NodeType.MODAC && this.props.isAreaManagement === false) ? this.props.path : (this.props.path + "|" + childNodeMap.id)} /* doesn't append to path when node is modac id if states are required*/
                        isEditing={childNodeMap.id === this.props.editingNodeId}
                        parentLevel={level !== undefined ? level.level : undefined}
                        inheritOfStore={childNodeMap.node.isStore === true || this.props.inheritOfStore === true}
                    />
                })}


            </>
        );

    }
}

TreeNode.propTypes = {
    node: PropTypes.object.isRequired,
    level: PropTypes.number.isRequired,
    levelType: PropTypes.any.isRequired,
    parentLevel: PropTypes.any,
    onSelectModac: PropTypes.func.isRequired,
    onNodeSelect: PropTypes.func.isRequired,
    maxLevel: PropTypes.any,

    onNewNodeCreated: PropTypes.func,
    onNodeAddingCancelled: PropTypes.func,
    onNodeEditionCancelled: PropTypes.func,
    onNodeDeleted: PropTypes.func,
    onNodeEdited: PropTypes.func,

    nodeId: PropTypes.string,
    path: PropTypes.string,

    isAreaManagement: PropTypes.bool,

    onAddClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onDeleteNodeClick: PropTypes.func,
    onDeleteDeviceClick: PropTypes.func,

    newNodeAdding: PropTypes.any,
    editingNodeId: PropTypes.string,

    hideVisibleNodes: PropTypes.bool,
    hideStoreNodes: PropTypes.bool,
    hideArchiveDevices: PropTypes.bool,

    onMediaspotDropped: PropTypes.func,
    onSensorDropped: PropTypes.func,
    
    onDeviceDroppedToBeReplaced: PropTypes.func,

    inheritOfStore: PropTypes.bool,

    orgTypeUUID: PropTypes.string

};

TreeNode.defaultProps = {
    level: 0,
};

export default TreeNode;
