import { useEffect, useState } from "react";
import {
  GetAlarmsApiResponse,
  getAlerts,
  getDetections,
  GetDetectionsApiResponse,
} from "../api/DetectionsRequests";
import { getPathString } from "../helpers/factories/FactoriesHelper";
import classes from "../home/Home.module.css";
import { Event } from "../models/Event";
import Loader from "react-loader-spinner";
import { Interval } from "../navigation/tabs/Tabs";
import { start } from "repl";
import { getLastWeekInterval, timestampToDate } from "../helpers/DateHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { findHost } from "../helpers/MappingSecurispot";
import { DateTime } from "luxon";
import saveAs from "file-saver";
import { subHours, subDays } from "date-fns";
import { getI18n } from "react-i18next";
import { DatePicker } from "rsuite";
import i18n from "../i18n";

interface IProps {
  // areaNodePath: string,
  // factories: any
  numberDays: number | undefined;
  heigth: string | undefined;
  type: number | undefined;
  serial: string | undefined;
  title: string | undefined;
}

export const AlarmsList = (props: IProps) => {
  const currentUrl: string = window.location.href;
  
  //Today at midnight in local timezone
  const todayMidnightTimestamp = new Date(
    new Date().setHours(0, 0, 0, 0)
  ).getTime();

  const [timer, setTimer] = useState<NodeJS.Timer>();
  const [alarms, setAlarms] = useState<GetAlarmsApiResponse | undefined>(
    undefined
  );
  const [isLoading, setLoading] = useState<boolean>(true);
  const [start, setStart] = useState<number>(
    todayMidnightTimestamp - 1000 * 60 * 60 * 24 * (props.numberDays! - 1)
  );
  const [end, setEnd] = useState<number>(Date.now());

  const loadDetections = async () => {
    setLoading(true);
    const response = await getAlerts(
      start,
      end,
      Interval.DAY,
      props.serial,
      props.type
    );
    setLoading(false);

    if (response.error) {
      console.log("error during detections loading");
      return;
    }

    setAlarms(response.data);
  };

  const onDateChange = (date: Date | undefined) => {
    if (date) {
      setStart(date.getTime());
      setEnd(date.getTime() + (1000 * 60 * 60 * 24 - 1));
    }
  };

  useEffect(() => {
    loadDetections();
    console.log("useEffect", start, end);
  }, [start, props.serial]);

  const downloadData = () => {
    if (alarms === undefined) {
      return;
    }
    const csvContent =
      "Securispot;Date;Description;Durée\n" +
      alarms
        .map(
          (alarm) =>
            `${alarm.securispot};${DateTime.fromISO(alarm.date).toFormat("yyyy-MM-dd HH:mm:ss")};${
              alarm.description
            };${alarm.duration.toFixed(2)} sec`
        )
        .join("\n");
    const securi = props.serial
      ? findHost(props.serial)
      : "tous-les-SecuriSpots";
    const startDateString = DateTime.fromISO(
      new Date(start).toISOString()
    ).toFormat("yyyy-MM-dd");
    const endDateString = DateTime.fromISO(
      new Date(end).toISOString()
    ).toFormat("yyyy-MM-dd");
    const fileName =
      `${securi}_${startDateString}` +
      `${startDateString !== endDateString ? "_" + endDateString : ""}` +
      "_liste.csv";

    // https://stackoverflow.com/questions/19492846/javascript-to-csv-export-encoding-issue
    const csvBlob = new Blob(["\ufeff" + csvContent], {
      type: "text/csv;charset=utf-8",
    });

    saveAs(csvBlob, fileName);
  };

  if (isLoading) {
    return (
      <div className={classes.StatsItemContainer}>
        <label>Liste des événements</label>
        <div className={classes.HomeTitleSeparator} />
        <div className={classes.HomeTableContainer}>
          <div style={{ padding: "20px" }}>
            <Loader type={"Oval"} width={40} height={40} color={"#0095ff"} />
          </div>
        </div>
      </div>
    );
  }

  if (alarms && alarms.length === 0) {
    return (
      <div className={classes.StatsItemContainer}>
        <div className={classes.HeadRow}>
          {timestampToDate(start) ===
          timestampToDate(end) ? (
            <label>
              {i18n.t("EventList")} ({timestampToDate(start)}) - {props.title} -{" "}
              {alarms?.length} {i18n.t("Alerts")}
            </label>
          ) : (
            <label>
              {i18n.t("EventList")} ({timestampToDate(start)} -{" "}
              {timestampToDate(end)}) - {props.title} - {alarms?.length}{" "}
              {i18n.t("Alerts")}
            </label>
          )}
          {currentUrl.endsWith("alarms") ? undefined : (
            <DatePicker
              disabledDate={(date: Date | undefined) => {
                const firstAllowed: Date = new Date(2023, 0, 1);
                return (
                  date!.getTime() > Date.now() ||
                  date!.getTime() < firstAllowed.getTime()
                );
              }}
              placement={"bottomEnd"}
              cleanable={false}
              value={
                start !== undefined && end !== undefined
                  ? new Date(start)
                  : undefined
              }
              placeholder={getI18n().t("SelectPeriod")}
              onChange={onDateChange}
              ranges={[
                {
                  label: i18n.t("Today"),
                  value: new Date(new Date().setHours(0, 0, 0, 0)),
                },
                {
                  label: i18n.t("Yesterday"),
                  value: new Date(
                    new Date(Date.now() - 86400000).setHours(0, 0, 0, 0)
                  ),
                },
              ]}
            />
          )}
        </div>
        <div className={classes.HomeTitleSeparator} />
        <div className={classes.HomeTableContainer}>
          <div>
            <label className={classes.DetectionsListNoItemLabel}>
              {i18n.t("NoAlarm")}
            </label>
          </div>
        </div>
      </div>
    );
  }

  const filteredAndSortedAlarms = alarms
    ?.filter((alarm) => {
      return alarm.duration > 0;
    })
    .sort((a, b) => {
      return new Date(a.date).getTime() < new Date(b.date).getTime() ? 1 : -1;
    });

  return (
    <div className={classes.StatsItemContainer}>
      <div className={classes.HeadRow}>
        {timestampToDate(start) ===
        timestampToDate(end - 1000 * 60 * 60 * 2) ? (
          <label>
            {i18n.t("EventList")} ({timestampToDate(start)}) - {props.title} -{" "}
            {alarms?.length} {i18n.t("Alerts")}
          </label>
        ) : (
          <label>
            {i18n.t("EventList")} ({timestampToDate(start)} -{" "}
            {timestampToDate(end)}) - {props.title} - {alarms?.length}{" "}
            {i18n.t("Alerts")}
          </label>
        )}
        {currentUrl.endsWith("alarms") ? undefined : (
          <DatePicker
            disabledDate={(date: Date | undefined) => {
              const firstAllowed: Date = new Date(2023, 0, 1);
              return (
                date!.getTime() > Date.now() ||
                date!.getTime() < firstAllowed.getTime()
              );
            }}
            placement={"bottomEnd"}
            cleanable={false}
            value={
              start !== undefined && end !== undefined
                ? new Date(start)
                : undefined
            }
            placeholder={getI18n().t("SelectPeriod")}
            onChange={onDateChange}
            ranges={[
              {
                label: i18n.t("Today"),
                value: new Date(new Date().setHours(0, 0, 0, 0)),
              },
              {
                label: i18n.t("Yesterday"),
                value: new Date(
                  new Date(Date.now() - 86400000).setHours(0, 0, 0, 0)
                ),
              },
            ]}
          />
        )}
      </div>
      <div className={classes.HomeTitleSeparator} />
      <div
        className={classes.HomeTableContainer}
        style={{
          overflowY: "auto",
          maxHeight: props.heigth ? props.heigth : "61vh",
        }}
      >
        {filteredAndSortedAlarms?.length === 0 ? (
          <label style={{ padding: "20px", display: "block" }}>
            Aucune alarme
          </label>
        ) : (
          <>
            <table className={classes.Table}>
              <thead>
                <tr>
                  <th>{i18n.t("Securispot")}</th>
                  <th>{i18n.t("Date")}</th>
                  <th>{i18n.t("Description")}</th>
                  <th>{i18n.t("Duration")}</th>
                </tr>
              </thead>
              <tbody>
                {filteredAndSortedAlarms?.map((alarm) => {
                  return (
                    <tr
                      className={
                        Date.now() - new Date(alarm.date).getTime() < 1000 * 10
                          ? classes.HomeRecentEventRow
                          : undefined
                      }
                      key={alarm.id}
                    >
                      <td>{alarm.securispot}</td>
                      <td>{DateTime.fromISO(alarm.date).toFormat("yyyy-MM-dd HH:mm:ss")}</td>
                      <td>{alarm.description}</td>
                      <td>{alarm.duration.toFixed(2)} sec</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
      <div className={classes.ContainerDownloadButton}>
        <button className={classes.DownloadButton} onClick={downloadData}>
          <FontAwesomeIcon icon={faDownload} />
          <span>{i18n.t("Export")}</span>
        </button>
      </div>
    </div>
  );
};
