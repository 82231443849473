export const NodeType = {
    AREA: "area",
    MEDIASPOT: "mediaspot",
    SENSOR: "sensor"
}


export const getNodeTypeFromString = (typeString:string) => {
    if(typeString === undefined){
        return NodeType.AREA
    }
    switch (typeString.toLowerCase()){
        case NodeType.MEDIASPOT: return NodeType.MEDIASPOT
        case NodeType.SENSOR: return NodeType.SENSOR
        default: return NodeType.AREA
    }
}
