import { useEffect, useState } from "react"
import { getLastEventDate } from "../api/DetectionsRequests"

import Card from "../card/Card"
import { secToTextWithoutUselessUnit } from "../helpers/DateHelper"

interface IProps {
    
}

export const LastEventDateCard = (props: IProps) => {

    const [timer, setTimer] = useState<NodeJS.Timer>()
    const [lastEventDate, setLastEventDate] = useState<string|undefined>(undefined)
    const [lastRequestTimestamp, setLastRequestTimestamp] = useState<number|undefined>(undefined)
    const [isLoading, setLoading] = useState<boolean>(true)

    const loadLastEventDate = async() => {
        const response = await getLastEventDate()
        setLoading(false)

        if(response.error){
            console.log("error during get last event date loading")
            return
        }
        console.log(JSON.stringify(response.data))

        console.log("update lasteventdate with ", response.data?.date)
        setLastRequestTimestamp(Date.now())
        setLastEventDate(response.data?.date)
    }
    
    useEffect(() => {
        if(timer){
            clearInterval(timer)
        }
        setTimer(setInterval(async() => {
            await loadLastEventDate()
        }, 15000))
    }, [])
    

    return  <Card
        forceLoadingDisplay={isLoading}
        style={{width: "350px", marginRight: "10px", marginBottom: "15px", display: "inline-block"}}
        title={"Dernières données reçues"}
        value={(lastEventDate === undefined) ? "-" : secToTextWithoutUselessUnit(((Date.now() - new Date(lastEventDate).getTime())/1000).toString()) }
        backgroundColor={"#1F9BD9"}
    />
}